import React, { useEffect, useRef, useState, useCallback } from 'react';
import Hero from "./components/Hero";
import Departments from "./components/Departments";
import Newsletter from "./components/Newsletter";
import Recruitment from "./components/Recruitment";
import Sponsors from "./components/Sponsors";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Events from "./components/Events";
import { getEvents } from "./services/gallery";
import Loading from "./components/Loading";

function Home() {
  const [activeSection, setActiveSection] = useState('');
  const sectionsRef = useRef({});
  const [events, setEvents] = useState([]);
  const [eventsLoading, setEventsLoading] = useState(true);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const unsub = getEvents((fetchedEvents) => {
      setEvents(fetchedEvents);
      setEventsLoading(false);
    });
    return () => {
      unsub();
    };
  }, []);

  const setSectionRef = useCallback((node) => {
    if (node !== null) {
      sectionsRef.current[node.id] = node;
    }
  }, []);

  useEffect(() => {
    if (!eventsLoading && imagesLoaded) {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: [0.6],
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const sectionId = entry.target.id;
            setActiveSection(sectionId);
            console.log(`Active section set to: ${sectionId}`);
          }
        });
      }, options);

      const currentSections = sectionsRef.current;
      Object.values(currentSections).forEach(section => {
        observer.observe(section);
        console.log(`Observing section: ${section.id}`);
      });

      return () => {
        Object.values(currentSections).forEach(section => {
          observer.unobserve(section);
        });
      };
    }
  }, [eventsLoading, imagesLoaded]);

  useEffect(() => {
    console.log(`activeSection updated: ${activeSection}`);
  }, [activeSection]);

  useEffect(() => {
    const handleImageLoad = () => {
      const images = document.querySelectorAll('img');
      const allImagesLoaded = Array.from(images).every(img => img.complete && img.naturalHeight !== 0);
      if (allImagesLoaded) {
        setImagesLoaded(true);
      }
    };

    const images = document.querySelectorAll('img');
    images.forEach(img => {
      if (img.complete) {
        handleImageLoad();
      } else {
        img.addEventListener('load', handleImageLoad);
        img.addEventListener('error', handleImageLoad);
      }
    });

    return () => {
      images.forEach(img => {
        img.removeEventListener('load', handleImageLoad);
        img.removeEventListener('error', handleImageLoad);
      });
    };
  }, []);

  if (eventsLoading || !imagesLoaded) {
    return <Loading />;
  }

  return (
    <div className="overflow-y-scroll h-screen flex-grow z-0 scroll-smooth overflow-x-hidden">
      <NavBar activeSection={activeSection} />
      
      <section ref={setSectionRef} className="hero-bg w-full" id="team">
        <Hero />
      </section>
      
      <section ref={setSectionRef} id="departments">
        <Departments />
      </section>
      <section ref={setSectionRef} id="sponsors">
        <Sponsors />
      </section>
      <section ref={setSectionRef} id="gallery">
        <Events events={events} />
      </section>
      <section ref={setSectionRef} id="newsletter">
        <Newsletter />
      </section>
      <div className="recruitment-bg">
        <div className="flex flex-col justify-center items-center">
          <section ref={setSectionRef} className="w-full" id="recruitment">
            <Recruitment />
          </section>
          <section ref={setSectionRef} className="w-full" id="footer">
            <Footer />
          </section>
        </div>
        
      </div>
      
    </div>
  );
}

export default Home;
