import { db, storage } from "../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";
import emailjs from 'emailjs-com';



export const submitRecruitment = async (formData) => {
    const { name, email, phone, cv, course, year, motivation } = formData;
    
    
    try {

        const storageRef = ref(storage, `cvs/${cv.name}`);
        const snapshot = await uploadBytes(storageRef, cv);
        const url = await getDownloadURL(snapshot.ref);

        const mailData = {
            to_name: name,
            to_email: email,
        };
    
        await addDoc(collection(db, "recruitment"), {
            name,
            email,
            phone,
            cvUrl: url,
            course,
            year,
            motivation,
            timestamp: new Date()
        });

        const serviceId = process.env.REACT_APP_EMAIL_SERVICE_ID;
        const templateId = process.env.REACT_APP_EMAIL_TEMPLATE_ID;
        emailjs.send(serviceId, templateId,mailData, process.env.REACT_APP_EMAIL_PUBLIC_USER_ID);

        alert("Thank you for submitting your application!");

    } catch (e) {
        console.error("Error submitting application:", e);
        alert("Error submitting application. Please try again later.");
    }
};
