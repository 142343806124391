
/**
 * Department must be one of the following:
 * 1. "MS"
 * 2. "ES"
 * 3. "HB"
 * 4. "MM"
 */
function sortDataByDepartment(data, department) {
    return data.filter(item => item.department === department);
}

function sortDataByRole(data) {
    const roleHierarchy = {
        "Team Leader": 1,
        "Management & Marketing Leader": 2,
        "Electrical Systems Leader": 3,
        "Mechanical Systems Leader": 4,
        "Hull & Body Leader": 5,
        "Electrical Systems Technical Director": 6,
        "Mechanical Systems Technical Director": 7,
        "Hull & Body Technical Director": 8,
        "Management & Marketing Technical Director": 9,
        "Technical Director": 10
    };

    return data.sort((a, b) => {
        const roleA = roleHierarchy[a.role] || 11; 
        const roleB = roleHierarchy[b.role] || 11; 

        return roleA - roleB;
    });
}


function sortMembers(data, department) {
    const filteredData = department?.trim() ? sortDataByDepartment(data, department) : data;
    return sortDataByRole(filteredData);
}

export { sortDataByDepartment, sortDataByRole, sortMembers };
